<template>
  <v-dialog
    :value="popup"
    max-width="600px"
    eager
    min-height
    scrollable
    persistent
    @keydown.esc="closeDialog"
  >
    <v-card>
      <v-card-title> Upload camera parameters json file </v-card-title>
      <v-divider />

      <v-card-text class="pt-5">
        <Autocomplete
          v-model="selectedCamera"
          item-value="exid"
          item-text="name"
          label="Camera"
          no-filter
          :cache-items="false"
          resource="cameras"
        />
        <v-file-input
          v-model="uploadParameters"
          accept=".json"
          label="Upload Camera Parameters"
          @change="convertParameters"
        />
        <div v-if="cameraParameters" :class="paramsColor">
          <CameraParameters :camera-parameters="cameraParameters" />
        </div>

        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDialog">Cancel</v-btn>
          <v-btn
            color="primary"
            text
            class="float-right"
            @click="uploadCameraPrameters"
          >
            Upload
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import CameraParameters from "@/components/bimUploader/CameraParameters.vue"
import Autocomplete from "@evercam/shared/components/Autocomplete"
import { EvercamApi } from "@evercam/shared/api/evercamApi"

export default {
  components: { CameraParameters, Autocomplete },
  props: {
    popup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedCamera: null,
      uploadParameters: null,
      cameraParameters: null,
      uploadedParameters: false,
    }
  },
  computed: {
    paramsColor() {
      return this.uploadedParameters ? "blue--text" : "grey--text"
    },
  },
  watch: {
    async selectedCamera(camera) {
      if (camera) {
        try {
          let response = await EvercamApi.bim.getCameraParameters(camera.exid, {
            apiId: camera.userApiId,
            apiKey: camera.userApiKey,
          })
          this.cameraParameters = JSON.parse(response)
          this.uploadParameters = null
          this.uploadedParameters = true
        } catch (error) {
          this.cameraParameters = null
          if (error?.response?.status === 404) {
            this.$notifications.warn(
              `Please, Upload a camera parameters json file for ${camera.name}`
            )
          } else {
            this.$notifications.error({
              text: "Cannot load camera parameters!",
              error,
            })
          }
        }
      }
    },
  },
  methods: {
    convertParameters(file) {
      if (file) {
        let reader = new FileReader()
        reader.onload = (e) => {
          let json = JSON.parse(e.target.result)
          this.cameraParameters = json
          this.uploadedParameters = false
        }
        reader.readAsText(file)
      } else {
        this.cameraParameters = null
      }
    },
    closeDialog() {
      this.$emit("onClose")
      this.selectedCamera = null
      this.uploadParameters = null
      this.cameraParameters = null
    },
    async uploadCameraPrameters() {
      if (this.cameraParameters && this.selectedCamera) {
        try {
          await EvercamApi.bim.uploadCameraParameters(
            this.selectedCamera.exid,
            {
              distortionCoeffs: this.cameraParameters.distortion_coeffs,
              horizontalFov: this.cameraParameters.horizontal_FOV,
              verticalFov: this.cameraParameters.vertical_FOV,
              focalLength1: this.cameraParameters.focal_length1,
              focalLength2: this.cameraParameters.focal_length2,
              centerX: this.cameraParameters.centerX,
              centerY: this.cameraParameters.centerY,
              apiId: this.selectedCamera.userApiId,
              apiKey: this.selectedCamera.userApiKey,
            }
          )
          this.$notifications.success("Uploading camera parameters.")
        } catch (error) {
          this.$notifications.error({
            text: "Failed update camera parameters!",
            error,
          })
        } finally {
          this.closeDialog()
        }
      } else {
        this.$notifications.warn(
          "Please provide a camera and a parameters .json file."
        )
      }
    },
  },
}
</script>
