<template>
  <div>
    <h3>Camera Parameters</h3>
    <p>
      <strong>Distortion Coeficients:</strong>
      {{ cameraParameters.distortion_coeffs }}
    </p>
    <p>
      <strong>Horizontal FOV:</strong>
      {{ cameraParameters.horizontal_FOV }}
    </p>
    <p>
      <strong>Vertical FOV:</strong>
      {{ cameraParameters.vertical_FOV }}
    </p>
    <p>
      <strong>Focal Length 1:</strong>
      {{ cameraParameters.focal_length1 }}
    </p>
    <p>
      <strong>Focal Length 2:</strong>
      {{ cameraParameters.focal_length2 }}
    </p>
    <p><strong>Center X:</strong> {{ cameraParameters.centerX }}</p>
    <p><strong>Center y:</strong> {{ cameraParameters.centerY }}</p>
  </div>
</template>

<script>
export default {
  name: "CameraParameters",
  props: {
    cameraParameters: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
