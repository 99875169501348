<template>
  <v-dialog
    :value="uploadPopup"
    max-width="600px"
    eager
    min-height
    scrollable
    persistent
    @keydown.esc="closeDialog"
  >
    <v-card>
      <v-card-title> Upload ZIP file with the BIM images </v-card-title>
      <v-divider />
      <v-card-text class="pt-5">
        <Autocomplete
          v-model="selectedUpload"
          item-value="exid"
          item-text="name"
          label="Camera"
          no-filter
          :cache-items="false"
          resource="cameras"
        />
        <div class="dropbox">
          <input
            id="file-upload"
            type="file"
            accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed,image/png"
            :disabled="isSaving"
            class="input-file caption"
            @change="onFileChange"
          />
          <p v-if="isInitial">
            Drag your video here to begin<br />
            or click to browse
          </p>
          <p v-if="isSaving">Uploading {{ fileCount }} %</p>
          <p v-if="isSuccess">
            {{ selectedFileName }}
          </p>
          <p v-if="isFailed">
            Failed, click here or drag your<br />video here to try again
          </p>
        </div>
        <div v-if="selectedFileName !== ''">
          Selected File: <b>{{ selectedFileName }}</b>
        </div>
        <v-checkbox
          v-model="distortToggle"
          label="Apply distortion"
          class="resize-checkbox"
        />
        <v-select
          v-model="model"
          :items="models"
          dense
          outlined
          item-text="name"
          item-value="value"
          label="Model"
          :disabled="displayedName.trim() !== ''"
        ></v-select>
        <v-text-field
          v-model="displayedName"
          label="Displayed name"
          dense
          outlined
        />
        <v-select
          v-model="software"
          :items="softwares"
          dense
          outlined
          item-text="name"
          item-value="value"
          label="Software"
        ></v-select>
        <CameraParameters
          v-if="cameraParameters"
          :camera-parameters="cameraParameters"
        />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialog()">Cancel</v-btn>
        <v-btn color="primary" text class="float-right" @click="uploadFile()">
          Upload
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CameraParameters from "@/components/bimUploader/CameraParameters.vue"
import Autocomplete from "@evercam/shared/components/Autocomplete"
import { BimModelTypes } from "@/components/constants.js"
import { BimModelType } from "@evercam/shared/types/bim"
import * as tus from "tus-js-client/dist/tus.min.js"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { BimUploadStatus } from "@evercam/shared/types"
import { useAccountStore } from "@/stores/account"
import { mapStores } from "pinia"

export default {
  components: { CameraParameters, Autocomplete },
  props: {
    uploadPopup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fileCount: 0,
      title: "",
      file: null,
      finalView: false,
      fileExtension: null,
      fileUrl: null,
      software: "synchro",
      distortToggle: true,
      softwares: [
        { name: "Synchro", value: "synchro" },
        { name: "Navisworks", value: "navisworks" },
      ],
      model: BimModelType.Architectural,
      displayedName: "",
      models: BimModelTypes,
      currentStatus: BimUploadStatus.Initial,
      selectedFileName: "",
      selectedUpload: null,
      cameraParameters: null,
      fileName: "",
    }
  },
  computed: {
    ...mapStores(useAccountStore),
    isInitial() {
      return this.currentStatus === BimUploadStatus.Initial
    },
    isSaving() {
      return this.currentStatus === BimUploadStatus.Saving
    },
    isSuccess() {
      return this.currentStatus === BimUploadStatus.Success
    },
    isFailed() {
      return this.currentStatus === BimUploadStatus.Failed
    },
  },
  watch: {
    async selectedUpload(camera) {
      if (camera) {
        try {
          let response = await EvercamApi.bim.getCameraParameters(camera.exid, {
            apiId: camera.userApiId,
            apiKey: camera.userApiKey,
          })
          this.cameraParameters = JSON.parse(response)
        } catch (error) {
          this.cameraParameters = null
          if (error?.response?.status === 404) {
            this.$notifications.warn(
              `Please, Upload a camera parameters json file for ${camera.name}`
            )
          } else {
            this.$notifications.error({
              text: "Cannot load camera parameters!",
              error,
            })
          }
        }
      }
    },
  },
  methods: {
    onFileChange() {
      const fileInput = document.querySelector("#file-upload")
      this.file = fileInput.files[0]
      if (this.file) {
        this.selectedFileName = this.file?.name
        this.currentStatus = BimUploadStatus.Success
      }
    },
    uploadFile() {
      return new Promise((resolve) => {
        this.file = document.querySelector("#file-upload").files[0]
        var upload = new tus.Upload(this.file, {
          endpoint: `${this.$config.public.portalURL}`,
          storeFingerprintForResuming: true,
          chunkSize: Infinity,
          retryDelays: [0, 1000, 3000, 5000],
          parallelUploads: 1,
          metadata: {
            filename: this.file?.name,
            filetype: this.file?.type,
          },
          uploadSize: this.file?.size,
          onProgress: (bytesUploaded, bytesTotal) => {
            var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2)
            console.log(bytesUploaded, bytesTotal, percentage + "%")
            this.fileCount = percentage
            this.currentStatus = BimUploadStatus.Saving
          },
          onSuccess: () => {
            console.log("Download %s from %s", upload.file.name, upload.url)
            this.fileUrl = upload.url
            this.title = upload.file.name.split(".")[0]
            this.fileExtension = upload.file.name.split(".")[1]
            this.currentStatus = BimUploadStatus.Success
            this.createArchive(this.selectedUpload)
          },
          onError: (error) => {
            console.log("Failed because: " + error)
            this.currentStatus = BimUploadStatus.Failed
          },
        })
        upload.start()
        resolve()
      })
    },
    async createArchive(camera) {
      try {
        await EvercamApi.bim.createBim(camera.exid, {
          title: this.title,
          software: this.software,
          model: this.model,
          displayName: this.displayedName,
          fileUrl: this.fileUrl,
          requestor: this.accountStore.email,
          finalView: this.fileExtension !== "zip",
          enableDistortion: this.distortToggle,
          startAt: new Date().toISOString(),
          apiId: camera.userApiId,
          apiKey: camera.userApiKey,
        })
        this.closeDialog()
        this.$notifications.success("We are processing your request!")
      } catch (error) {
        this.$notifications.error({
          text: "Failed to create BIM archive!",
          error,
        })
      }
      this.closeDialog()
    },
    closeDialog() {
      this.$emit("onClose")
      this.title = ""
      this.file = null
      this.software = "synchro"
      this.model = BimModelType.Architectural
      this.currentStatus = BimUploadStatus.Initial
      this.selectedFileName = ""
      this.selectedUpload = null
      this.selectedFileName = ""
      this.cameraParameters = null
      this.distortToggle = true
    },
  },
}
</script>

<style scoped>
.dropbox {
  outline: 2px dashed grey;
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 150px;
  height: 150px;
  position: relative;
  cursor: pointer;
}
.input-file {
  opacity: 0;
  width: 100%;
  height: 150px;
  position: absolute;
  cursor: pointer;
}
.dropbox:hover {
  background: lightblue;
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 25px 0;
}
</style>
